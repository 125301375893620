export default {
  namespaced: true,
  state: {
    onlineNum: 0,
    cpu: [],
    memory: [],
    network: [],
    disk: [],
    channel: { online: 0, total: 0 },
    device: { online: 0, total: 0 },
    proxy: { online: 0, total: 0 },
    push: { online: 0, total: 0 },
    notification: {
      pool: [],
      msgListAvailable: true,
      msgList: [],
    },
  },
}
