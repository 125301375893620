<template>
  <el-table-column class="ui-table-column" v-on="$listeners" v-bind="{ ...$attrs }">
    <template slot-scope="scope">
      <slot v-if="$scopedSlots.default" v-bind="scope" />
      <template v-else-if="scope.column.property">
        {{ scope.row[scope.column.property] }}
      </template>
    </template>
  </el-table-column>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.ui-table-column {
}
</style>
