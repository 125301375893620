import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const expireTime = 60 * 1000 * 60 * 24

let loadPromiseResolve
const loadPromise = new Promise(r => {
  loadPromiseResolve = r
})
window.addEventListener('load', () => {
  loadPromiseResolve()
})

export async function getToken() {
  const token = await (new Promise(async (resolve) => {
    if (!sessionStorage.VUE_APP_LOGIN_PERSISTENCE) {
      resolve(Cookies.get(TokenKey))
    } else {
      await loadPromise
      const storage = await window.$loginPersistenceUtils.getStorage()
      if (storage.token) {
        resolve(storage.token)
      } else {
        const token = await window.$loginPersistenceUtils.toLogin()
        resolve(token)
      }
    }
  }))
  return token
}

export function setToken(token) {
  const expires = new Date(Date.now() + expireTime)
  return Cookies.set(TokenKey, token, { expires })
}

export function removeToken() {
  return Cookies.remove(TokenKey)
}
