import request from '@/utils/request.js'
//验证码
export function verification() {
  return request({
    url: '/account/verification',
    method: 'post',
    config: {
      token: false,
    },
  })
}
//登录
export function userLogin(data) {
  return request({
    url: '/account/web/login',
    method: 'post',
    data,
    config: {
      token: false,
    },
  })
}
//修改密码
export function modifyPassword(data) {
  return request({
    url: '/account/modifyPassword',
    method: 'post',
    data,
  })
}
//获取系统信息
export function systemInfo() {
  return request({
    url: '/system/info',
    method: 'post',
  })
}
//国标设备统计信息
export function systemResourceInfo() {
  return request({
    url: '/system/resource/info',
    method: 'post',
  })
}
//通过token获取用户信息(未修改)
export function selfInfo() {
  return request({
    url: '/account/selfInfo',
    method: 'post',
  })
}
//设备列表(未修改)
export function deviceMapDeviceList() {
  return request({
    url: '/deviceMap/deviceList',
    method: 'post',
  })
}
//查询多个用户信息
export function moreUser(data) {
  return request({
    url: '/account/moreUser/',
    method: 'post',
    data,
  })
}
//智慧作业-数据监控
//获取气体检测设备
export function gasDetectionDeviceList(data) {
  return request({
    url: '/gas/detection/deviceList',
    method: 'post',
    data,
  })
}
//企业获取手表设备
export function watchDeviceList(data) {
  return request({
    url: '/watch/deviceList',
    method: 'post',
    data,
  })
}
//实时获取浓度信息
export function gasDetectionConcentration(data) {
  return request({
    url: '/gas/detection/concentration',
    method: 'post',
    data,
  })
}
//实时获取心率信息
export function watchHeartRate(data) {
  return request({
    url: '/watch/heartRate',
    method: 'post',
    data,
  })
}
//搜索手表设备
export function watchSearch(data) {
  return request({
    url: '/watch/search',
    method: 'post',
    data,
  })
}
//搜索气体检测设备
export function gasDetectionSearch(data) {
  return request({
    url: '/gas/detection/search',
    method: 'post',
    data,
  })
}
//实时地图
//电子围栏
//分页获取电子围栏
export function fenceList(data) {
  return request({
    url: '/fence/list',
    method: 'post',
    data,
  })
}
//分页获取围栏用户
export function fenceUserList(data) {
  return request({
    url: '/fence/userList',
    method: 'post',
    data,
  })
}

//给围栏添加用户
export function fenceAddUser(data) {
  return request({
    url: '/fence/addUser',
    method: 'post',
    data,
  })
}
//添加电子围栏
export function fenceAdd(data) {
  return request({
    url: '/fence/add',
    method: 'post',
    data,
  })
}
//修改电子围栏信息
export function fenceEdit(data) {
  return request({
    url: '/fence/edit',
    method: 'post',
    data,
  })
}
//删除电子围栏
export function fenceDelete(data) {
  return request({
    url: '/fence/delete',
    method: 'post',
    data,
  })
}
//删除围栏用户(未查询到)
export function fenceDeleteUsers(data) {
  return request({
    url: '/fence/deleteUsers',
    method: 'post',
    data,
  })
}
//用户列表
export function accountUserList(data) {
  return request({
    url: '/account/userList',
    method: 'post',
    data,
  })
}
//获取轨迹用户列表
export function gpsUserList(data) {
  return request({
    url: '/gps/userList',
    method: 'post',
    data,
  })
}
//获取用户轨迹
export function gpsUserTrack(data) {
  return request({
    url: '/gps/user/track',
    method: 'post',
    data,
  })
}
//智慧相册
//获取企业下所有文件
export function smartFiles(data) {
  return request({
    url: '/smart/files',
    method: 'post',
    data,
  })
}
//智慧拍传搜索
export function smartSearch(data) {
  return request({
    url: '/smart/search',
    method: 'post',
    data,
  })
}
//文件下载(未修改)
export function smartDownload(data) {
  return request({
    url: '/smart/download',
    method: 'get',
    data,
  })
}
//详情
export function smartInfo(data) {
  return request({
    url: '/smart/info',
    method: 'post',
    data,
  })
}
//删除文件
export function smartDeleteFile(data) {
  return request({
    url: '/smart/deleteFile',
    method: 'post',
    data,
  })
}
//企业查询用户
export function companyUsers(data) {
  return request({
    url: '/company/users',
    method: 'post',
    data,
  })
}
//通讯录列表
export function addressBookListV2(data) {
  return request({
    url: '/addressBook/list/v2',
    method: 'post',
    data,
  })
}
//视频列表(未修改)
export function videoCentreVideoList(data) {
  return request({
    url: '/videoCentre/videoList',
    method: 'post',
    data,
  })
}
//视频通道(未修改)
export function deviceChannel(data) {
  return request({
    url: '/videoCentre/device/channel',
    method: 'post',
    data,
  })
}
//多通道查询
export function deviceChannels(data) {
  return request({
    url: '/videoCentre/device/channels',
    method: 'post',
    data,
  })
}
//获取录制列表
export function getMp4RecordFile(data) {
  return request({
    url: '/videoCentre/getMp4RecordFile',
    method: 'post',
    data,
  })
}
//云台控制
export function videoCentreOperate(data) {
  return request({
    url: '/videoCentre/operate',
    method: 'post',
    data,
  })
}
//开启音频(未修改)
export function enableAudio(data) {
  return request({
    url: '/gb/device/enableAudio',
    method: 'post',
    data,
  })
}
//开始录制
export function gbDeviceStartRecord(data) {
  return request({
    url: '/gb/device/startRecord',
    method: 'post',
    data,
  })
}
//停止录制
export function gbDeviceStopRecord(data) {
  return request({
    url: '/gb/device/stopRecord',
    method: 'post',
    data,
  })
}
//获取语音对讲权限(未修改)
export function talkAuto(data) {
  return request({
    url: '/gb/device/talk/auto',
    method: 'post',
    data,
  })
}
//创建语音对讲推流(未修改)
export function talkCreate(data) {
  return request({
    url: '/gb/device/talk/create',
    method: 'post',
    data,
  })
}
//开始语音对讲(未修改)
export function talkStart(data) {
  return request({
    url: '/gb/device/talk/start',
    method: 'post',
    data,
  })
}
//拉流(未修改)
export function livePlay(data) {
  return request({
    url: '/live/play',
    method: 'post',
    data,
  })
}
//拉流2(未修改)
export function livePlay2(data) {
  return request({
    url: '/live/play2',
    method: 'post',
    data,
  })
}
//推流(未修改)
export function livePush(data) {
  return request({
    url: '/live/push',
    method: 'post',
    data,
  })
}
//oa模块
//签到记录
export function signInRecord(data) {
  return request({
    url: '/signIn/signInRecord',
    method: 'post',
    data,
  })
}
//创建工单
export function createWork(data) {
  return request({
    url: '/work/create',
    method: 'post',
    data,
  })
}
//修改工单
export function modifyWork(data) {
  return request({
    url: '/work/modifyWork',
    method: 'post',
    data,
  })
}
//查看列表
export function selectList(data) {
  return request({
    url: '/work/selectList',
    method: 'post',
    data,
  })
}
//工单类型-列表
export function workTypeList() {
  return request({
    url: '/workType/list',
    method: 'post',
  })
}
//审批工单
export function approvalCenter() {
  return request({
    url: '/approvalCenter/data',
    method: 'post',
  })
}
export function accountUserInfo(data) {
  return request({
    url: '/account/userInfo',
    method: 'post',
    data,
  })
}
//待处理
export function tobeProcessed(data) {
  return request({
    url: '/approvalCenter/tobeProcessed',
    method: 'post',
    data,
  })
}
//已处理
export function processed(data) {
  return request({
    url: '/approvalCenter/processed',
    method: 'post',
    data,
  })
}
//已发起
export function initiated(data) {
  return request({
    url: '/approvalCenter/initiated',
    method: 'post',
    data,
  })
}
//我收到
export function iReceived(data) {
  return request({
    url: '/approvalCenter/iReceived',
    method: 'post',
    data,
  })
}
//上传附件
// export function accessory(data) {
//   return request({
//     url:'/workStream/accessory',
//     method:'post',
//     data
//   })
// }
//同意
export function agree(data) {
  return request({
    url: '/workStream/agree',
    method: 'post',
    data,
  })
}
//驳回
export function reject(data) {
  return request({
    url: '/workStream/reject',
    method: 'post',
    data,
  })
}
//工单类型-添加
export function addWorkType(data) {
  return request({
    url: '/workType/add',
    method: 'post',
    data,
  })
}
//工单类型-删除
export function deleteType(data) {
  return request({
    url: '/workType/delete',
    method: 'post',
    data,
  })
}
//工单类型-修改
export function editType(data) {
  return request({
    url: '/workType/edit',
    method: 'post',
    data,
  })
}
//工单详情
export function workOneInfo(data) {
  return request({
    url: '/work/oneInfo',
    method: 'post',
    data,
  })
}
//角色列表
export function roleList() {
  return request({
    url: '/account/roleList',
    method: 'post'
  })
}
//电杆巡检
//获取企业账号下所有区域
export function areaList() {
  return request({
    url: '/poles/getAreaList',
    method: 'post'
  })
}
//获取区域下所有路线
export function roadList() {
  return request({
    url: '/poles/getRoadPolesList',
    method: 'post'
  })
}
//获取路线下所有电杆
export function roadPolesList() {
  return request({
    url: '/poles/getRoadPolesList',
    method: 'post'
  })
}
//修改电杆信息
export function updateRoadPoles() {
  return request({
    url: '/poles/update',
    method: 'post'
  })
}
//删除电杆
export function deleteRoadPoles() {
  return request({
    url: '/poles/delete',
    method: 'post'
  })
}
//删除多个电杆
export function deleteMoreRoadPoles() {
  return request({
    url: '/poles/deleteMore',
    method: 'post'
  })
}

