import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'
window.NProgress = NProgress

NProgress.configure({
  showSpinner: false,
})

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  if (to.meta.title) {
    document.title = to.meta.title
  }
  let token = await getToken()
  if (token) {
    next()
  } else {
    if (to.name === 'login') {
      next()
    } else {
      location.href = window.origin + '/login?redirect=' + to.path
    }
  }
})

router.afterEach((a, b, c) => {
  NProgress.done()
})
