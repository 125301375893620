<template>
  <el-button
    class="ui-button"
    :class="{
      's--6':size === -6,
      's--4': size === -4,
      's--2': size === -2,
      's--1': size === -1,
      's-1': size === 1,
      's-2': size === 2,
    }"
    v-on="$listeners"
    v-bind="{ ...$attrs }"
  >
    <slot />
  </el-button>
</template>
<script>
export default {
  props: {
    size: {
      type: Number,
      default() {
        return -1
      },
    },
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
$paddingTop: 14;
$paddingLeft: 20;
$font-size: 20;
.ui-button {
  @include prop(margin, 0);
  @include prop(border-width, 2);
  @include prop(border-radius, 0);
  @include prop(min-width, 0);
  &.s--6 {
    @include prop(padding, $paddingTop * 0.6 $paddingLeft * 0.6);
    @include prop(font-size, $font-size * 0.4);
  }
  &.s--4 {
    @include prop(padding, $paddingTop * 0.6 $paddingLeft * 0.6);
    @include prop(font-size, $font-size * 0.6);
  }
  &.s--2 {
    @include prop(padding, $paddingTop * 0.8 $paddingLeft * 0.8);
    @include prop(font-size, $font-size * 0.8);
  }
  &.s--1 {
    @include prop(padding, $paddingTop * 0.9 $paddingLeft * 0.9);
    @include prop(font-size, $font-size * 0.9);
  }
  &.s-1 {
    @include prop(padding, $paddingTop * 1.1 $paddingLeft * 1.1);
    @include prop(font-size, $font-size * 1.1);
  }
  &.s-2 {
    @include prop(padding, $paddingTop * 1.2 $paddingLeft * 1.2);
    @include prop(font-size, $font-size * 1.2);
  }
}
</style>
