<template>
  <el-dialog class="ui-dialog" v-on="$listeners" v-bind="{ ...$attrs }">
    <template slot="title" v-if="$slots.title">
      <slot name="title" />
    </template>
    <slot />
    <template slot="footer" v-if="$slots.footer">
      <slot name="footer" />
    </template>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.ui-dialog {
  :deep .el-dialog {
    margin: 0 auto 0 auto !important;
    top: 50%;
    transform: translateY(-50%);
    max-height: 90vh;
    min-width: 650px;
    display: flex;
    flex-direction: column;
    .el-dialog__header {
      @include prop(padding, 20 20 12 22);
      border-bottom: solid #f0f0f0;
      @include prop(border-width, 1);
      flex-shrink: 0;
    }
    .el-dialog__title {
      @include prop(font-size, 20);
      @include prop(line-height, 20);
    }
    .el-dialog__headerbtn {
      @include prop(top, 18);
      @include prop(right, 20);
      @include prop(font-size, 24);
    }
    .el-dialog__body {
      flex-shrink: 1;
      height: 100%;
      overflow-y: auto;
      @include prop(padding, 24 16);
      @include prop(font-size, 16);
    }
  }
}
</style>
