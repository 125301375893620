export default class WebSpeech {
  static _ins
  constructor() {
    if (window.speechSynthesis && window.SpeechSynthesisUtterance) {
      this.synth = window.speechSynthesis
      this.msg = new SpeechSynthesisUtterance()
      this.msg.lang = 'zh-CN'
      this.msg.volume = 1
      this.msg.rate = 1
      this.msg.pitch = 1

      // this.msg.addEventListener('start', function () {
      //   window.console.debug('start')
      // })
      // this.msg.addEventListener('end', function () {
      //   window.console.debug('end')
      // })
    } else {
      console.debug('浏览器不支持语音播报')
    }

    WebSpeech._ins = this
    return this
  }
  get instance() {
    return WebSpeech._ins || new WebSpeech()
  }

  onvoiceschanged(a, b, c) {
    window.console.debug('abc', a, b, c)
  }

  speak(text) {
    if (this.msg && this.synth) {
      this.msg.text = text
      this.synth.speak(this.msg)
    }
  }
}
