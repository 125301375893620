<template>
  <el-time-picker class="ui-time-picker w100" v-on="$listeners" v-bind="{ ...$attrs }">
    <slot />
  </el-time-picker>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.ui-time-picker {
  @include prop(padding, 4 14);
  @include prop(border-radius, 0);
  @include prop(height, 42);
  @include prop(line-height, 24);
  @include prop(font-size, 10);
  :deep .el-input__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    @include prop(font-size, 22);
    @include prop(margin, 0);
    @include prop(line-height, 0);
  }
  :deep .el-range-input {
    @include prop(font-size, inherit);
  }
  :deep .el-range-separator {
    display: flex;
    align-items: center;
    justify-content: center;
    @include prop(font-size, inherit);
  }
}
</style>
<style lang="scss">
.el-time-range-picker {
  .el-time-range-picker__header {
    @include prop(font-size, 16);
  }
  .el-time-range-picker__content {
    @include prop(padding, 0 12);
  }
}
</style>
