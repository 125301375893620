<template>
  <el-popover class="ui-popover" v-on="$listeners" v-bind="{ ...$attrs }">
    <template v-if="$slots.reference">
      <slot name="reference" />
    </template>
    <slot />
  </el-popover>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.ui-popover {
}
</style>
<style lang="scss">
.el-popover {
  @include prop(padding, 12 16);
  @include prop(min-width, 0);
  @include prop(font-size, 18);
  @include prop(border-radius, 0);
}
</style>
