import Vue from 'vue'

const state = {
  selectedDeviceId: '',
  ViewMonitoringDevices: [],
  deviceInfo: {},
  operateDeviceInfo: {},
  zoomInStatus: false,
}

const mutations = {
  SET_SELECTEDDEVICEID: (state, selectedDeviceId) => {
    state.selectedDeviceId = selectedDeviceId
  },
  ADD_VIEWMONITORINGDEVICESs: (state, ViewMonitoringDevice) => {
    state.ViewMonitoringDevices.push(ViewMonitoringDevice)
  },
  DECREASE_VIEWMONITORINGDEVICESs: (state, index) => {
    Vue.delete(state.ViewMonitoringDevices, index)
  },
  SET_DEVICEINFO: (state, deviceInfo) => {
    state.deviceInfo = deviceInfo
  },
  SET_OPERATEDEVICEINFO: (state, operateDeviceInfo) => {
    state.operateDeviceInfo = operateDeviceInfo
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
