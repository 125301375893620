<template>
  <div class="flex wrapper">
    <el-input ref="input" class="ui-input shrink1" v-on="$listeners" v-bind="{ ...$attrs }">
      <slot />
      <template slot="suffix" v-if="$slots.suffix">
        <slot name="suffix" />
      </template>
    </el-input>
    <div class="tail shrink0" v-if="$slots.tail">
      <slot name="tail" />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },

  methods: {},
}
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;
}
.ui-input {
  width: 100%;
  @include prop(font-size, 16);
  :deep .el-input__inner,
  :deep .el-textarea__inner {
    font-family: 'Microsoft Yahei';
    @include prop(border-radius, 0);
    @include prop(border-width, 1);
    @include prop(height, 42);
    @include prop(line-height, 24);
    @include prop(padding, 8 12);
  }
  :deep .el-input__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    @include prop(font-size, 18);
    @include prop(margin, 0);
    @include prop(line-height, 0);
  }
  :deep .el-input__prefix {
    @include prop(left, 8);
  }
  :deep .el-input__suffix {
    @include prop(right, 8);
  }
}
.tail {
  color: rgb(96, 98, 102);
  @include prop(margin, 0 0 0 16);
}
</style>
