import { render, staticRenderFns } from "./s-container.vue?vue&type=template&id=922e755a&scoped=true"
var script = {}
import style0 from "./s-container.vue?vue&type=style&index=0&id=922e755a&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.6_css-loader@6.11.0_webpack@5.94.0__lodash@4.17.21_v_nlqk7vo2zc6ayaxamjxrjj553y/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "922e755a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\workspace\\gmd-top-platform-web@radar\\node_modules\\.pnpm\\vue-hot-reload-api@2.3.4\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('922e755a')) {
      api.createRecord('922e755a', component.options)
    } else {
      api.reload('922e755a', component.options)
    }
    module.hot.accept("./s-container.vue?vue&type=template&id=922e755a&scoped=true", function () {
      api.rerender('922e755a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/layout/components/s-container.vue"
export default component.exports