export default {
  visible: {
    groupChat: false,
    call: false,
    imageView: false,
  },
  dialogs: {
    groupChat: null,
    call: null,
  },
  isLogingOut: false,
  webrtcDialog: {
    visible: false,
    device: {
      uid: '',
      streamId: '',
    },
    userItem: {},
  },
  loader: {
    isLoaded: false,
  },
}
