<template>
  <div
    :style="{
      transform: `scale(${zoom})`,
    }"
    :class="'size' + size"
    class="zoom-font"
    v-on="$listeners"
    v-bind="{ ...$attrs }"
    ref="font"
  >
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    size: {
      default() {
        return 17
      },
    },
  },
  data() {
    return {
      baseDevicePixelRatio: 1.25,
      zoom: 0,
      minSize: 0,
      curSize: (this.size / 1920) * 100,
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onresize)
  },
  created() {
    this.onresizev2()
  },
  mounted() {
    window.addEventListener('resize', this.onresize)
  },
  methods: {
    onresizev2() {
      let scale = window.devicePixelRatio / this.baseDevicePixelRatio - 1
      if (scale > 0) {
        let zoom = this.baseDevicePixelRatio / window.devicePixelRatio
        this.zoom = zoom * (1 + scale * 0.25)
      } else {
        this.zoom = 1
      }
      this.minSize = (12 / Math.max(1000, innerWidth)) * 100
      if (this.curSize < this.minSize) {
        this.zoom = this.zoom * (this.curSize / this.minSize)
      }
    },
    onresize() {
      if (this.timer) {
        cancelAnimationFrame(this.timer)
      }
      this.timer = requestAnimationFrame(this.onresizev2)
    },
  },
}
</script>
<style lang="scss" scoped>
@for $i from 12 through 32 {
  .size#{$i} {
    @include prop(font-size, $i);
  }
}
.zoom-font {
  transform-origin: 50% 50%;
}
</style>
