<template>
  <el-option
    class="ui-option"
    :class="{
      mini: size === 'mini',
    }"
    :size="size"
    v-on="$listeners"
    v-bind="{ ...$attrs }"
  >
    <slot />
  </el-option>
</template>
<script>
export default {
  props: {
    size: {
      type: String,
      default() {
        return 'medium'
      },
    },
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss" scoped>
.ui-option {
  @include prop(border-radius, 0);
  @include prop(border-width, 1);
  @include prop(height, 42);
  @include prop(line-height, 24);
  @include prop(padding, 8 12);
  @include prop(font-size, 16);
  &.mini {
    @include prop(height, 42);
    @include prop(font-size, 14);
  }
}
</style>
