const state = {
  microPlayer: null,
}

const mutations = {}

const actions = {
  microPlayerCheck({ commit, dispatch, getters, rootGetters, rootState, state }) {},
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
