<template>
  <el-table class="ui-table" v-on="$listeners" v-bind="{ ...$attrs }">
    <slot />
    <template slot="empty">
      <el-empty></el-empty>
    </template>
  </el-table>
</template>
<script>
export default {
  data() {
    return {}
  },
}
</script>
<style lang="scss">
.ui-table.el-table {
  @include prop(font-size, 16);
  .cell {
    @include prop(line-height, 24);
  }
  .el-table__cell {
    @include prop(padding, 12 0);
  }
  .el-table__cell:first-child .cell {
    @include prop(padding-left, 10);
  }
  th.el-table__cell {
    background: #fafafa;
  }
  th.el-table__cell > .cell {
    @include prop(padding-right, 10);
  }
  &.el-table--border {
  }
  &::before {
    display: none;
  }
}
</style>
